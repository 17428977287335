<template>
  <div id="setting">
    <div class="titleLine">
      <progress-circle-bar :value="progress" size= 60></progress-circle-bar>
      <div class="titleBox">
        <h1>{{$t(`lang.generalProfole`)}}</h1>
        <h3>
            {{$t(`lang.generalProfoleText`)}}
        </h3>
      </div>
    </div>
    <div class="menuBox">
      <div class="menuLine">
        <div class="menu" @click="goPersonal">
          <img src="@/icon/man.png">
          <h2>{{$t(`lang.personalInformation`)}}</h2>
          <h3>{{$t(`lang.personalInformationText`)}}</h3>
        </div>
        <div class="menu" @click="goSecurity">
          <img src="@/icon/shield.png">
          <h2>{{$t(`lang.security`)}}</h2>
          <h3>{{$t(`lang.securityText`)}}</h3>
        </div>
      </div>
      <div class="menuLine">
        <div class="menu" @click="goInvestmentPreference">
          <svg-icon name="stock" :size="svgSize"/>
          <h2>{{$t(`lang.investmentPreference`)}}</h2>
          <h3>{{$t(`lang.investmentPreferenceText`)}}</h3>
        </div>
        <div class="menu" @click="goBrokers">
          <svg-icon name="broker" :size="svgSize"/>
          <h2>{{$t(`lang.brokersSetting`)}}</h2>
          <h3>{{$t(`lang.brokersSettingText`)}}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressCircleBar from '@/components/progressBar/ProgressCircleBar.vue'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'

export default {
  name:'Setting',
  components: {
    ProgressCircleBar,
    SvgIcon,

  },
  data() {
    return {
      progress:10,
      svgSize:"80"
    }
  },
  methods:{
    goPersonal(){
      this.$router.push("/usercenter/setting/personal")
    },
    goSecurity(){
      this.$router.push("/usercenter/setting/security")
    },
    goInvestmentPreference(){
      this.$router.push("/usercenter/setting/investment")
    },
    goBrokers(){
      this.$router.push("/usercenter/setting/brokers")
    }
  },
}
</script>

<style scoped>
#setting{
  width: 100%;
  height: 100%;
  background: #212024;
  color: aliceblue;
}
.titleLine{
  position: relative;
  height: 160px;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}
.titleBox{
  padding-left: 60px;
  width: 40%;
}
h3{
  color: rgb(153, 153, 153);
}
h1{
  margin-top: 10px;
  font-size: 32px;
}
h2{
  margin-top: 10px;
  font-size: 22px;
}
.menuLine{
  position: relative;
  display: flex;
  box-sizing: border-box;
  height: 50%;
}
.menuBox{
  position: relative;
}
.menu{
  position: relative;
  width: 458px;
  height: 30%;
  min-height: 270px;
  background: #2C2D30;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  margin-right: 40px;
  margin-bottom: 40px;
  padding-top: 30px;
  box-sizing: border-box;
  padding-left: 30px;
  transition: .4s;
}
.menu img{
  height: 80px;
}
.menu:hover{
  transform: scale(1.05);
  cursor: pointer;
}
</style>
